/* sidebar */
.sidebar{

}

.logo {
    height: 100px;
    width: 80px;
    /* background: rgba(255, 255, 255, 0.2); */
    padding: 20px;
}

.sidebar-toggle-button{
    position: absolute;
    bottom: 30px;
    left: 25px;
}

.ant-menu-vertical{
    border-right: none;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right: none;
}

.ant-layout-sider{
    z-index: 1;
    box-shadow: #eaeaea 3px 0px 10px;
}

/* header */
.ant-layout-header{
    border-bottom: 1px solid #efefef;
}

.ant-breadcrumb .anticon {
    font-size: 9px;
}

.ant-breadcrumb{
    font-size: 13px;
    font-variant-caps: all-small-caps;
}

.main-header-notification-icon {
    /* margin: 0 35px; */
}

.header-icon{
    font-size: 18px;
    margin: 5px;
}

/* user popover */

.user-popover-container{
    padding: 10px;
}

.user-popover-user-container{
    /* margin-bottom: 20px; */
    text-align: center;
}

.user-popover-avatar-container{
    /* margin-bottom: 10px; */
}

.user-popover-avatar-name{
    font-weight: 600;
}
.user-popover-avatar-email{
    font-size: small;
    margin-top: 10;
}

.user-popover-user-sign-out-button{
    font-size: small;
    font-weight: 600;
}

/* notification popover */

.notification-container{
    max-height: 80vh;
    overflow: auto;
    margin-right: -10px;
    display: inline-block;
}

.header-strip-container{
    padding: 20px;
    background: white;
}

.user-popover-avatar-role{
    font-size: small;
    margin-top: 11;
}